exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-consiliul-local-atributii-js": () => import("./../../../src/pages/consiliul-local/atributii.js" /* webpackChunkName: "component---src-pages-consiliul-local-atributii-js" */),
  "component---src-pages-consiliul-local-componenta-2012-2016-js": () => import("./../../../src/pages/consiliul-local/componenta-2012-2016.js" /* webpackChunkName: "component---src-pages-consiliul-local-componenta-2012-2016-js" */),
  "component---src-pages-consiliul-local-componenta-2016-2020-js": () => import("./../../../src/pages/consiliul-local/componenta-2016-2020.js" /* webpackChunkName: "component---src-pages-consiliul-local-componenta-2016-2020-js" */),
  "component---src-pages-consiliul-local-componenta-2020-2024-js": () => import("./../../../src/pages/consiliul-local/componenta-2020-2024.js" /* webpackChunkName: "component---src-pages-consiliul-local-componenta-2020-2024-js" */),
  "component---src-pages-consiliul-local-componenta-js": () => import("./../../../src/pages/consiliul-local/componenta.js" /* webpackChunkName: "component---src-pages-consiliul-local-componenta-js" */),
  "component---src-pages-consiliul-local-detalii-membru-js": () => import("./../../../src/pages/consiliul-local/detalii-membru.js" /* webpackChunkName: "component---src-pages-consiliul-local-detalii-membru-js" */),
  "component---src-pages-consiliul-local-hotarari-hotarari-2010-js": () => import("./../../../src/pages/consiliul-local/hotarari/hotarari-2010.js" /* webpackChunkName: "component---src-pages-consiliul-local-hotarari-hotarari-2010-js" */),
  "component---src-pages-consiliul-local-hotarari-hotarari-2011-js": () => import("./../../../src/pages/consiliul-local/hotarari/hotarari-2011.js" /* webpackChunkName: "component---src-pages-consiliul-local-hotarari-hotarari-2011-js" */),
  "component---src-pages-consiliul-local-hotarari-hotarari-2012-js": () => import("./../../../src/pages/consiliul-local/hotarari/hotarari-2012.js" /* webpackChunkName: "component---src-pages-consiliul-local-hotarari-hotarari-2012-js" */),
  "component---src-pages-consiliul-local-hotarari-hotarari-2013-js": () => import("./../../../src/pages/consiliul-local/hotarari/hotarari-2013.js" /* webpackChunkName: "component---src-pages-consiliul-local-hotarari-hotarari-2013-js" */),
  "component---src-pages-consiliul-local-hotarari-hotarari-2014-js": () => import("./../../../src/pages/consiliul-local/hotarari/hotarari-2014.js" /* webpackChunkName: "component---src-pages-consiliul-local-hotarari-hotarari-2014-js" */),
  "component---src-pages-consiliul-local-hotarari-hotarari-2015-js": () => import("./../../../src/pages/consiliul-local/hotarari/hotarari-2015.js" /* webpackChunkName: "component---src-pages-consiliul-local-hotarari-hotarari-2015-js" */),
  "component---src-pages-consiliul-local-hotarari-hotarari-2016-js": () => import("./../../../src/pages/consiliul-local/hotarari/hotarari-2016.js" /* webpackChunkName: "component---src-pages-consiliul-local-hotarari-hotarari-2016-js" */),
  "component---src-pages-consiliul-local-hotarari-hotarari-2017-js": () => import("./../../../src/pages/consiliul-local/hotarari/hotarari-2017.js" /* webpackChunkName: "component---src-pages-consiliul-local-hotarari-hotarari-2017-js" */),
  "component---src-pages-consiliul-local-hotarari-hotarari-2018-js": () => import("./../../../src/pages/consiliul-local/hotarari/hotarari-2018.js" /* webpackChunkName: "component---src-pages-consiliul-local-hotarari-hotarari-2018-js" */),
  "component---src-pages-consiliul-local-hotarari-hotarari-2019-js": () => import("./../../../src/pages/consiliul-local/hotarari/hotarari-2019.js" /* webpackChunkName: "component---src-pages-consiliul-local-hotarari-hotarari-2019-js" */),
  "component---src-pages-consiliul-local-hotarari-hotarari-2020-js": () => import("./../../../src/pages/consiliul-local/hotarari/hotarari-2020.js" /* webpackChunkName: "component---src-pages-consiliul-local-hotarari-hotarari-2020-js" */),
  "component---src-pages-consiliul-local-hotarari-hotarari-2021-js": () => import("./../../../src/pages/consiliul-local/hotarari/hotarari-2021.js" /* webpackChunkName: "component---src-pages-consiliul-local-hotarari-hotarari-2021-js" */),
  "component---src-pages-consiliul-local-hotarari-hotarari-2022-js": () => import("./../../../src/pages/consiliul-local/hotarari/hotarari-2022.js" /* webpackChunkName: "component---src-pages-consiliul-local-hotarari-hotarari-2022-js" */),
  "component---src-pages-consiliul-local-hotarari-hotarari-2023-js": () => import("./../../../src/pages/consiliul-local/hotarari/hotarari-2023.js" /* webpackChunkName: "component---src-pages-consiliul-local-hotarari-hotarari-2023-js" */),
  "component---src-pages-consiliul-local-hotarari-hotarari-2024-js": () => import("./../../../src/pages/consiliul-local/hotarari/hotarari-2024.js" /* webpackChunkName: "component---src-pages-consiliul-local-hotarari-hotarari-2024-js" */),
  "component---src-pages-consiliul-local-hotarari-index-js": () => import("./../../../src/pages/consiliul-local/hotarari/index.js" /* webpackChunkName: "component---src-pages-consiliul-local-hotarari-index-js" */),
  "component---src-pages-consiliul-local-index-js": () => import("./../../../src/pages/consiliul-local/index.js" /* webpackChunkName: "component---src-pages-consiliul-local-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-date-cu-caracter-personal-js": () => import("./../../../src/pages/date-cu-caracter-personal.js" /* webpackChunkName: "component---src-pages-date-cu-caracter-personal-js" */),
  "component---src-pages-despre-comuna-adrese-utile-js": () => import("./../../../src/pages/despre-comuna/adrese-utile.js" /* webpackChunkName: "component---src-pages-despre-comuna-adrese-utile-js" */),
  "component---src-pages-despre-comuna-context-istoric-js": () => import("./../../../src/pages/despre-comuna/context-istoric.js" /* webpackChunkName: "component---src-pages-despre-comuna-context-istoric-js" */),
  "component---src-pages-despre-comuna-eroii-comunei-js": () => import("./../../../src/pages/despre-comuna/eroii-comunei.js" /* webpackChunkName: "component---src-pages-despre-comuna-eroii-comunei-js" */),
  "component---src-pages-despre-comuna-evenimente-js": () => import("./../../../src/pages/despre-comuna/evenimente.js" /* webpackChunkName: "component---src-pages-despre-comuna-evenimente-js" */),
  "component---src-pages-despre-comuna-index-js": () => import("./../../../src/pages/despre-comuna/index.js" /* webpackChunkName: "component---src-pages-despre-comuna-index-js" */),
  "component---src-pages-despre-comuna-institutii-js": () => import("./../../../src/pages/despre-comuna/institutii.js" /* webpackChunkName: "component---src-pages-despre-comuna-institutii-js" */),
  "component---src-pages-despre-comuna-prezentare-js": () => import("./../../../src/pages/despre-comuna/prezentare.js" /* webpackChunkName: "component---src-pages-despre-comuna-prezentare-js" */),
  "component---src-pages-harta-site-js": () => import("./../../../src/pages/harta-site.js" /* webpackChunkName: "component---src-pages-harta-site-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informatii-acte-necesare-js": () => import("./../../../src/pages/informatii/acte-necesare.js" /* webpackChunkName: "component---src-pages-informatii-acte-necesare-js" */),
  "component---src-pages-informatii-alegeri-locale-comunicate-js": () => import("./../../../src/pages/informatii/alegeri-locale/comunicate.js" /* webpackChunkName: "component---src-pages-informatii-alegeri-locale-comunicate-js" */),
  "component---src-pages-informatii-alegeri-locale-hotarari-js": () => import("./../../../src/pages/informatii/alegeri-locale/hotarari.js" /* webpackChunkName: "component---src-pages-informatii-alegeri-locale-hotarari-js" */),
  "component---src-pages-informatii-alegeri-locale-informatie-aep-js": () => import("./../../../src/pages/informatii/alegeri-locale/informatie-aep.js" /* webpackChunkName: "component---src-pages-informatii-alegeri-locale-informatie-aep-js" */),
  "component---src-pages-informatii-alegeri-locale-informatii-js": () => import("./../../../src/pages/informatii/alegeri-locale/informatii.js" /* webpackChunkName: "component---src-pages-informatii-alegeri-locale-informatii-js" */),
  "component---src-pages-informatii-angajari-js": () => import("./../../../src/pages/informatii/angajari.js" /* webpackChunkName: "component---src-pages-informatii-angajari-js" */),
  "component---src-pages-informatii-anunturi-js": () => import("./../../../src/pages/informatii/anunturi.js" /* webpackChunkName: "component---src-pages-informatii-anunturi-js" */),
  "component---src-pages-informatii-buget-js": () => import("./../../../src/pages/informatii/buget.js" /* webpackChunkName: "component---src-pages-informatii-buget-js" */),
  "component---src-pages-informatii-fiscalitate-js": () => import("./../../../src/pages/informatii/fiscalitate.js" /* webpackChunkName: "component---src-pages-informatii-fiscalitate-js" */),
  "component---src-pages-informatii-formulare-js": () => import("./../../../src/pages/informatii/formulare.js" /* webpackChunkName: "component---src-pages-informatii-formulare-js" */),
  "component---src-pages-informatii-index-js": () => import("./../../../src/pages/informatii/index.js" /* webpackChunkName: "component---src-pages-informatii-index-js" */),
  "component---src-pages-informatii-petitii-sesizari-js": () => import("./../../../src/pages/informatii/petitii-sesizari.js" /* webpackChunkName: "component---src-pages-informatii-petitii-sesizari-js" */),
  "component---src-pages-informatii-rapoarte-js": () => import("./../../../src/pages/informatii/rapoarte.js" /* webpackChunkName: "component---src-pages-informatii-rapoarte-js" */),
  "component---src-pages-informatii-stiri-js": () => import("./../../../src/pages/informatii/stiri.js" /* webpackChunkName: "component---src-pages-informatii-stiri-js" */),
  "component---src-pages-informatii-taxe-impozite-js": () => import("./../../../src/pages/informatii/taxe-impozite.js" /* webpackChunkName: "component---src-pages-informatii-taxe-impozite-js" */),
  "component---src-pages-informatii-transparenta-js": () => import("./../../../src/pages/informatii/transparenta.js" /* webpackChunkName: "component---src-pages-informatii-transparenta-js" */),
  "component---src-pages-mol-js": () => import("./../../../src/pages/mol.js" /* webpackChunkName: "component---src-pages-mol-js" */),
  "component---src-pages-plata-online-js": () => import("./../../../src/pages/plata-online.js" /* webpackChunkName: "component---src-pages-plata-online-js" */),
  "component---src-pages-politica-de-confidentialitate-js": () => import("./../../../src/pages/politica-de-confidentialitate.js" /* webpackChunkName: "component---src-pages-politica-de-confidentialitate-js" */),
  "component---src-pages-primar-atributii-js": () => import("./../../../src/pages/primar/atributii.js" /* webpackChunkName: "component---src-pages-primar-atributii-js" */),
  "component---src-pages-primar-biografie-js": () => import("./../../../src/pages/primar/biografie.js" /* webpackChunkName: "component---src-pages-primar-biografie-js" */),
  "component---src-pages-primar-curriculum-vitae-js": () => import("./../../../src/pages/primar/curriculum-vitae.js" /* webpackChunkName: "component---src-pages-primar-curriculum-vitae-js" */),
  "component---src-pages-primar-dispozitii-js": () => import("./../../../src/pages/primar/dispozitii.js" /* webpackChunkName: "component---src-pages-primar-dispozitii-js" */),
  "component---src-pages-primar-index-js": () => import("./../../../src/pages/primar/index.js" /* webpackChunkName: "component---src-pages-primar-index-js" */),
  "component---src-pages-primar-mesajul-primarului-js": () => import("./../../../src/pages/primar/mesajul-primarului.js" /* webpackChunkName: "component---src-pages-primar-mesajul-primarului-js" */),
  "component---src-pages-primarie-cetateni-js": () => import("./../../../src/pages/primarie/cetateni.js" /* webpackChunkName: "component---src-pages-primarie-cetateni-js" */),
  "component---src-pages-primarie-conducere-js": () => import("./../../../src/pages/primarie/conducere.js" /* webpackChunkName: "component---src-pages-primarie-conducere-js" */),
  "component---src-pages-primarie-detalii-functionar-js": () => import("./../../../src/pages/primarie/detalii-functionar.js" /* webpackChunkName: "component---src-pages-primarie-detalii-functionar-js" */),
  "component---src-pages-primarie-functionari-js": () => import("./../../../src/pages/primarie/functionari.js" /* webpackChunkName: "component---src-pages-primarie-functionari-js" */),
  "component---src-pages-primarie-index-js": () => import("./../../../src/pages/primarie/index.js" /* webpackChunkName: "component---src-pages-primarie-index-js" */),
  "component---src-pages-primarie-organigrama-js": () => import("./../../../src/pages/primarie/organigrama.js" /* webpackChunkName: "component---src-pages-primarie-organigrama-js" */),
  "component---src-pages-primarie-proiecte-js": () => import("./../../../src/pages/primarie/proiecte.js" /* webpackChunkName: "component---src-pages-primarie-proiecte-js" */),
  "component---src-pages-primarie-registru-js": () => import("./../../../src/pages/primarie/registru.js" /* webpackChunkName: "component---src-pages-primarie-registru-js" */),
  "component---src-pages-primarie-servicii-publice-asistenta-sociala-js": () => import("./../../../src/pages/primarie/servicii-publice/asistenta-sociala.js" /* webpackChunkName: "component---src-pages-primarie-servicii-publice-asistenta-sociala-js" */),
  "component---src-pages-primarie-servicii-publice-index-js": () => import("./../../../src/pages/primarie/servicii-publice/index.js" /* webpackChunkName: "component---src-pages-primarie-servicii-publice-index-js" */),
  "component---src-pages-primarie-servicii-publice-sadp-js": () => import("./../../../src/pages/primarie/servicii-publice/sadp.js" /* webpackChunkName: "component---src-pages-primarie-servicii-publice-sadp-js" */),
  "component---src-pages-primarie-servicii-publice-svsu-js": () => import("./../../../src/pages/primarie/servicii-publice/svsu.js" /* webpackChunkName: "component---src-pages-primarie-servicii-publice-svsu-js" */),
  "component---src-pages-primarie-servicii-publice-taxe-impozite-js": () => import("./../../../src/pages/primarie/servicii-publice/taxe-impozite.js" /* webpackChunkName: "component---src-pages-primarie-servicii-publice-taxe-impozite-js" */),
  "component---src-pages-primarie-servicii-publice-urbanism-js": () => import("./../../../src/pages/primarie/servicii-publice/urbanism.js" /* webpackChunkName: "component---src-pages-primarie-servicii-publice-urbanism-js" */),
  "component---src-pages-primarie-stare-civila-js": () => import("./../../../src/pages/primarie/stare-civila.js" /* webpackChunkName: "component---src-pages-primarie-stare-civila-js" */),
  "component---src-pages-primarie-structura-js": () => import("./../../../src/pages/primarie/structura.js" /* webpackChunkName: "component---src-pages-primarie-structura-js" */),
  "component---src-pages-termeni-si-conditii-js": () => import("./../../../src/pages/termeni-si-conditii.js" /* webpackChunkName: "component---src-pages-termeni-si-conditii-js" */),
  "component---src-pages-wp-post-title-js": () => import("./../../../src/pages/{WpPost.title}.js" /* webpackChunkName: "component---src-pages-wp-post-title-js" */)
}

